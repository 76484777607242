<template>
  <i-dialog :name="name">
    <p slot="title" class="fs-20 fw-b">{{ item.vendor_name }}</p>
    <el-row type="flex" align="middle" class="f-m-wrap">
      <el-col :sm="10" :xs="24" class="fs-20 pr-70 black ta-r mb-m-5 ta-m-l">
        Номер договора:
      </el-col>
      <el-col :sm="14" :xs="24" class="i-components">
        <el-input
          v-model="contract_number"
          autocomplete="nope"
          :readonly="!isPending"
        />
      </el-col>
    </el-row>
    <el-row type="flex" align="middle" class="f-m-wrap mt-30">
      <el-col :sm="10" :xs="24" class="fs-20 black pr-70 ta-r mb-m-5 ta-m-l">
        Дата заключения договора:
      </el-col>
      <el-col :sm="14" :xs="24" class="i-components">
        <el-date-picker
          v-if="isPending"
          v-model="contract_date"
          :picker-options="{ firstDayOfWeek: 1 }"
          type="date"
          format="dd.MM.yyyy"
          value-format="yyyy-MM-dd"
        />
        <el-input v-else :value="contract_date" autocomplete="nope" readonly />
      </el-col>
    </el-row>
    <el-row
      v-if="!isPending || (isPending && !is_unlimited)"
      type="flex"
      align="middle"
      class="f-m-wrap mt-30"
    >
      <el-col :sm="10" :xs="24" class="fs-20 black pr-70 ta-r mb-m-5 ta-m-l">
        Договор действует до:
      </el-col>
      <el-col v-if="item.is_unlimited" :sm="14" :xs="24">
        <span class="t-greyDark fs-20">Бессрочный</span>
      </el-col>
      <el-col v-else :sm="14" :xs="24" class="i-components">
        <el-date-picker
          v-if="isPending"
          v-model="expires_at"
          :picker-options="{ firstDayOfWeek: 1 }"
          type="date"
          format="dd.MM.yyyy"
          value-format="timestamp"
        />
        <el-input
          v-else
          :value="item.expires_at"
          autocomplete="nope"
          readonly
        />
      </el-col>
    </el-row>
    <el-row type="flex" align="middle" class="f-m-wrap mt-30">
      <el-col :sm="10" :xs="24" class="fs-20 pr-70 black ta-r mb-m-5 ta-m-l">
        Статус:
      </el-col>
      <el-col :sm="14" :xs="24" :class="['fs-20', item.status]">
        {{ getStatusAccreditation }}
      </el-col>
    </el-row>
    <el-row v-if="canProlongation" type="flex" class="f-m-wrap mt-30 mh-60">
      <el-col
        :sm="10"
        :xs="24"
        class="fs-20 pr-70 pt-17 black ta-r mb-m-5 ta-m-l"
      >
        Новый срок действия:
      </el-col>
      <el-col :sm="14" :xs="24" class="i-components df f-wrap ai-c">
        <el-date-picker
          v-if="!is_unlimited"
          v-model="expires_at"
          :picker-options="{ firstDayOfWeek: 1 }"
          type="date"
          format="dd.MM.yyyy"
          value-format="timestamp"
          class="mr-20 w-170"
        />
        <el-row type="flex" align="middle" class="my-10">
          <i-button
            type="danger"
            text="ОТМЕНА"
            width="115"
            fs="16"
            class="mr-30 bg-declined"
            @click="closeModal"
          />
          <i-button
            text="ПРОЛОНГИРОВАТЬ"
            width="190"
            fs="16"
            @click="putAccreditation"
          />
        </el-row>
      </el-col>
    </el-row>
    <el-row
      v-if="canProlongation || (isPending && !item.is_unlimited)"
      type="flex"
      class="mt-30"
    >
      <el-col :sm="10" />
      <el-col :sm="14" :xs="24" class="secondary-ch">
        <el-checkbox v-model="is_unlimited">договор бессрочный</el-checkbox>
      </el-col>
    </el-row>
    <el-row v-if="isPending" class="ta-r mt-30">
      <i-button
        text="ОБНОВИТЬ ДАННЫЕ"
        :loading="loading"
        :disabled="loading"
        width="290"
        fs="16"
        @click="putAccreditation"
      />
    </el-row>
  </i-dialog>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import IDialog from '@/components/Ui/Dialog'
import IButton from '@/components/Ui/Button'
import { accreditationStatuses } from '@/components/Ui/constants'
import { isValidDate } from '@/helpers/functions'

export default {
  name: 'AccreditationModalClient',
  components: { IDialog, IButton },
  data() {
    return {
      name: 'accreditation-modal-client',
      accreditationStatuses,
      contract_number: '',
      contract_date: '',
      expires_at: '',
      is_unlimited: false
    }
  },
  computed: {
    ...mapGetters({
      getDialog: 'popups/getDialog',
      getLoading: 'accreditations/getLoading'
    }),
    loading() {
      return this.getLoading()
    },
    item() {
      return this.getDialog(this.name).data || {}
    },
    getStatusAccreditation() {
      return accreditationStatuses[this.item.status] || ''
    },
    isPending() {
      return this.item.status === 'pending'
    },
    canProlongation() {
      return ['ok_expires_soon', 'expired'].includes(this.item.status)
    }
  },
  watch: {
    item(newVal) {
      this.contract_number = newVal.contract_number
      this.contract_date = isValidDate(newVal.contract_date)
        ? newVal.contract_date
        : ''
      this.expires_at = newVal.expires_at
      this.is_unlimited = newVal.is_unlimited
    }
  },
  methods: {
    ...mapMutations({ setDialog: 'popups/setDialog' }),
    ...mapActions('accreditations', ['UPDATE']),
    putAccreditation() {
      const { contract_number, contract_date, expires_at, is_unlimited } = this
      if (is_unlimited || (!is_unlimited && expires_at)) {
        const data = {
          id: this.item.id,
          data: {
            contract_number,
            contract_date,
            expires_at: expires_at / 1000,
            is_unlimited: is_unlimited ? 1 : 0
          }
        }
        this.UPDATE(data).then(res => {
          if (res !== 'error') {
            this.closeModal()
          }
        })
      } else this.$message.error('Укажите новый срок действия договора')
    },
    closeModal() {
      this.setDialog({ name: this.name })
    }
  }
}
</script>

<style lang="sass">
.accreditation-modal-client
  .el-button
    height: 40px
  .mh-60
    min-height: 60px
  .w-170
    width: 170px
  .pt-17
    padding-top: 17px
</style>
