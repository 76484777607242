<template>
  <i-dialog :name="name">
    <p slot="title" class="fs-20 fw-b">
      <span class="mr-70 db-m mr-m-0">№ {{ item.contract_number }}</span>
      <span class="mr-70 db-m mr-m-0 mt-m-5">{{ item.bidder_name }}.</span>
      <span class="db-m mt-m-5">{{ item.bidder_phone }}</span>
    </p>
    <el-row>
      <el-col :sm="8" :xs="24" class="fs-20 pr-70 ta-r black pr-m-0 ta-m-l">
        Дата создания заявки:
      </el-col>
      <el-col :sm="16" :xs="24" class="fs-20 black">
        {{ item.created_at | toLocaleDateTime('fullWithHours') }}
      </el-col>
    </el-row>
    <el-row
      type="flex"
      align="middle"
      justify="space-between"
      class="mt-20 mb-10 f-wrap"
    >
      <el-col
        :sm="8"
        :xs="24"
        class="fs-20 pr-70 ta-r black pr-m-0 ta-m-l mb-m-5"
      >
        Статус аккредитации:
      </el-col>
      <el-col :sm="16" :xs="24" class="i-components">
        <el-select v-model="actualStatus" placeholder="Выберите из списка">
          <el-option
            v-for="item in options"
            :key="item.value"
            :label="item.label"
            :value="item.value"
            :disabled="item.disabled"
          >
          </el-option>
        </el-select>
      </el-col>
    </el-row>
    <el-row class="i-components">
      <el-col
        :sm="8"
        :xs="24"
        class="fs-20 pr-70 mt-20 ta-r black pr-m-0 ta-m-l mb-m-5 mt-m-0"
      >
        Комментарий:
      </el-col>
      <el-col :sm="16" :xs="24" class="fs-20 black">
        <el-input
          v-model="actualComment"
          :autosize="{ minRows: 8, maxRows: 6 }"
          type="textarea"
        />
      </el-col>
    </el-row>
    <el-row
      type="flex"
      align="middle"
      justify="space-between"
      class="mt-40 f-wrap"
    >
      <el-col :span="12" :xs="24">
        <p class="fs-20 td-u cp t-grey fw-l ta-m-l">История изменений</p>
      </el-col>
      <el-col :span="12" :xs="24" class="ta-r">
        <i-button
          text="Сохранить"
          :loading="loading"
          :disabled="loading"
          width="180"
          @click="onClick"
        />
      </el-col>
    </el-row>
  </i-dialog>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from 'vuex'
import IDialog from '@/components/Ui/Dialog'
import IButton from '@/components/Ui/Button'
import { accModalCustomerFilterOptions } from '../constants'

export default {
  name: 'AccreditationModalCustomer',
  components: { IDialog, IButton },
  data() {
    return {
      name: 'accreditation-modal-customer',
      options: accModalCustomerFilterOptions,
      actualComment: '',
      actualStatus: ''
    }
  },
  computed: {
    ...mapGetters({
      getDialog: 'popups/getDialog',
      getLoading: 'accreditations/getLoading'
    }),
    loading() {
      return this.getLoading()
    },
    item() {
      return this.getDialog(this.name).data || {}
    }
  },
  watch: {
    item() {
      this.actualStatus = this.item.status
      this.actualComment = this.item.comment
    }
  },
  methods: {
    ...mapMutations({ setDialog: 'popups/setDialog' }),
    ...mapActions('accreditations', ['UPDATE']),
    onClick() {
      const data = {
        id: this.item.id,
        data: {
          contract_number: this.item.contract_number,
          comment: this.actualComment,
          status: this.actualStatus
        }
      }
      this.UPDATE(data).then(res => {
        if (res !== 'error') {
          this.setDialog({ name: this.name })
        }
      })
    }
  }
}
</script>
