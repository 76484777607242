<template>
  <el-table
    v-loading="loading"
    :data="tableData"
    empty-text="Нет данных"
    stripe
    :key="updateTable"
    :class="['table-accreditation mt-20', { 'at-empty': !tableData.length }]"
    style="width: 100%"
  >
    <el-table-column
      v-for="el in tableFields"
      :key="el.prop"
      :prop="el.prop"
      :width="el.width + 'px'"
      :min-width="el.minWidth ? `${el.minWidth}px` : ''"
    >
      <template slot="header">
        {{ el.label }}
        <template v-if="el.subLabel">
          <p v-for="sub in el.subLabel" :key="sub" class="m-0">{{ sub }}</p>
        </template>
      </template>
      <template slot-scope="props">
        <TableCell
          :row="props.row"
          :data="props.row[el.prop]"
          :prop-key="el.prop"
          :status="props.row.status"
          :is_unlimited="!!props.row.is_unlimited"
        />
      </template>
    </el-table-column>
    <el-table-column width="36px">
      <template slot-scope="scope">
        <div class="edit-icon" @click="onEdit(scope.$index, scope.row)" />
      </template>
    </el-table-column>
  </el-table>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'
import { phoneRegex } from '@/helpers/functions'
import TableCell from '@/components/Ui/TableCell'
import { getTableFields } from '../constants'

export default {
  name: 'AccreditationTable',
  components: { TableCell },
  computed: {
    ...mapGetters({
      getLib: 'libs/getLib',
      getAccreditations: 'accreditations/getList',
      getLoading: 'accreditations/getLoading'
    }),
    loading() {
      return this.getLoading()
    },
    organizations() {
      return this.getLib('organizations')
    },
    tableData() {
      return this.getAccreditations.map(item => {
        const phone = phoneRegex(item.bidder_phone)
        const orgName = this.organizations[item.vendor_org_id]?.name
        const extraFields = this.isBidder
          ? {
              dogovor_customer: [item.contract_number, orgName],
              vendor_name: orgName
            }
          : {
              name_login: [`${item.bidder_name}.`, phone]
            }
        return { ...item, ...extraFields, bidder_phone: phone }
      })
    },
    tableFields() {
      if (this.isMobile) return getTableFields(this.isBidder, 'Mobile')
      else if (this.isTablet) return getTableFields(this.isBidder, 'Table')
      else return getTableFields(this.isBidder)
    },
    updateTable() {
      if (this.isMobile) return 'mobile'
      else if (this.isTablet) return 'tablet'
      else return 'desktop'
    }
  },
  methods: {
    ...mapMutations({ setDialog: 'popups/setDialog' }),
    onEdit(index, row) {
      this.setDialog({
        name: `accreditation-modal-${this.isBidder ? 'client' : 'customer'}`,
        visible: true,
        data: row
      })
    }
  }
}
</script>

<style lang="sass">
.table-accreditation
  &.at-empty:before
    background-color: unset
  .contract_number
    font-weight: bold
    font-size: 13px
    line-height: 18px
    color: $textTableBody
  .comment
    &.table-cell-declined
      text-decoration-line: underline
      color: $redColor
  .dogovor_customer
    & div:first-of-type
      font-weight: bold
</style>
