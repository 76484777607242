<template>
  <page-wrapper :title="titles[index]">
    <fast-link-menu @change="onChange" />
    <div v-if="!index" class="mb-40">
      <p v-if="user.email_verified === 0" class="fs-20 t-grey m-0 mb-5">
        Для получения уведомлений по почте
        <span class="bb cp" @click="$router.push({ name: 'Profile' })">
          подтвердите ее.
        </span>
      </p>
      <p v-if="!isItems" class="fs-20 t-grey m-0 mb-5">
        Для получения аккредитации у Заказчиков требуется действующий договор
        перевозки.
      </p>
      <p v-if="!isItems" class="fs-20 t-grey m-0 mb-5">
        Если у вас нет действующего договора перевозки — обратитесь в службу
        поддержки, мы вам обязательно поможем!
      </p>
    </div>
    <filters
      accreditation
      :options="options"
      :filters="filters"
      @change="onChange"
      @add="$router.push({ name: 'AccreditationAdd' })"
    />
    <accreditation-table />
    <i-pagination :params="getPagination" @changed="onChangePage" />
    <accreditation-modal-client />
    <accreditation-modal-customer />
  </page-wrapper>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import Filters from '@/components/TablesFormsFilters/Filters'
import IPagination from '@/components/Ui/Pagination'
import PageWrapper from '@/components/Wrappers/PageWrapper'
import FastLinkMenu from '@/components/Reusable/FastLinkMenu'
import AccreditationTable from './components/AccreditationTable'
import AccreditationModalClient from './components/AccreditationModalClient'
import AccreditationModalCustomer from './components/AccreditationModalCustomer'
import { titlesMain, filterOptions } from './constants'

export default {
  name: 'Accreditations',
  components: {
    FastLinkMenu,
    AccreditationModalClient,
    AccreditationModalCustomer,
    AccreditationTable,
    Filters,
    IPagination,
    PageWrapper
  },
  data() {
    return {
      filters: {
        'per-page': 20,
        any: ''
      },
      page: 1,
      titles: titlesMain,
      options: filterOptions
    }
  },
  created() {
    this.getAccreditations()
  },
  computed: {
    ...mapGetters({
      currentStatus: 'accreditations/currentStatus',
      getPagination: 'accreditations/getPagination',
      isItems: 'accreditations/isItems'
    }),
    index() {
      if (this.isBidder) return 0
      return this.currentStatus === 'ok_expires_soon' ? 2 : 1
    }
  },
  methods: {
    ...mapMutations('accreditations', ['setCurrentStatus']),
    ...mapActions('accreditations', ['FETCH']),
    getAccreditations() {
      this.FETCH({
        ...this.filters,
        status: this.currentStatus,
        page: this.page
      })
    },
    onChange(key, val) {
      if (key) this.filters[key] = val
      this.getAccreditations()
    },
    onChangePage(page) {
      this.page = page
      this.getAccreditations()
    }
  },
  beforeDestroy() {
    this.setCurrentStatus('')
  }
}
</script>
