export const titles = [
  'ЗАПРОС НОВОЙ АККРЕДИТАЦИИ',
  'ЗАПРОС НА АККРЕДИТАЦИЮ ОТПРАВЛЕН'
]

export const texts = [
  'Для получения уведомлений по почте. Подтвердите ее.',
  'Мы уведомим вас о результате.'
]

export const titlesMain = [
  'АККРЕДИТАЦИЯ У ЗАКАЗЧИКОВ ПЕРЕВОЗКИ',
  'СПИСОК АККРЕДИТОВАННЫХ ИСПОЛНИТЕЛЕЙ',
  'СПИСОК ДОГОВОРОВ НА ПРОЛОНГАЦИЮ'
]

export const accreditationAddRules = {
  vendor_org_id: {
    required: true,
    message: 'Необходимо выбрать заказчика',
    trigger: 'blur'
  },
  contract_number: {
    required: true,
    message: 'Заполните номер договора',
    trigger: 'blur'
  },
  contract_date: {
    required: true,
    message: 'Заполните дату',
    trigger: 'blur'
  },
  expires_at: {
    required: true,
    message: 'Заполните дату',
    trigger: 'blur'
  }
}

export const filterOptions = [
  { value: 'accepted', label: 'Есть договор и аккредитация' },
  { value: 'ok_expires_soon', label: 'Нужна пролонгация' },
  { value: 'pending', label: 'Ждет аккредитации' },
  { value: 'declined', label: 'Заблокирован' },
  { value: 'expired', label: 'Истек договор' }
]

export const accModalCustomerFilterOptions = [
  { value: 'accepted', label: 'Аккредитован' },
  { value: 'declined', label: 'Заблокирован' },
  { value: 'pending', label: 'Ждет аккредитации', disabled: true },
  { value: 'ok_expires_soon', label: 'Нужна пролонгация', disabled: true },
  { value: 'expired', label: 'Истек договор', disabled: true }
]

export const getTableFields = (isBidder = false, type = '') => {
  return func[`atFields${type}`](isBidder)
}

const func = {
  atFields: isBidder => [
    { prop: 'created_at', label: 'ДАТА', subLabel: ['ДОБАВЛЕНИЯ'], width: 130 },
    {
      prop: 'contract_number',
      label: 'НОМЕР',
      subLabel: ['ДОГОВОРА'],
      width: 120
    },
    {
      prop: 'contract_date',
      label: 'ДАТА',
      subLabel: ['ЗАКЛЮЧЕНИЯ', 'ДОГОВОРА'],
      width: 150
    },
    {
      prop: 'expires_at',
      label: 'ДОГОВОР',
      subLabel: ['ДЕЙСТВУЕТ ДО'],
      width: 120
    },
    {
      prop: isBidder ? 'vendor_name' : 'name_login',
      label: isBidder ? 'НАИМЕНОВАНИЕ ЗАКАЗЧИКА' : 'ФИО',
      subLabel: isBidder ? '' : ['TЕЛЕФОН']
    },
    {
      prop: isBidder ? '' : 'bidder_org',
      label: isBidder ? '' : 'ОРГАНИЗАЦИЯ',
      subLabel: isBidder ? '' : ['ИНН'],
      width: isBidder ? 1 : ''
    },
    { prop: 'status', label: 'СТАТУС', width: 80 },
    { prop: 'comment', label: 'СОСТОЯНИЕ' }
  ],
  atFieldsTable: isBidder => [
    { prop: 'status', label: '', width: 31 },
    { prop: 'contract_date', label: 'ДОБАВЛЕНИЕ', minWidth: 120 },
    {
      prop: 'contract_number',
      label: 'НОМЕР',
      subLabel: ['ДОГОВОРА'],
      minWidth: 120
    },
    {
      prop: isBidder ? 'vendor_name' : 'name_login',
      label: isBidder ? 'НАИМЕНОВАНИЕ ЗАКАЗЧИКА' : 'ФИО',
      subLabel: isBidder ? '' : ['TЕЛЕФОН'],
      minWidth: 210
    }
  ],
  atFieldsMobile: isBidder => [
    { prop: 'status', label: '', width: 31 },
    { prop: 'contract_date', label: 'ДОБАВЛЕНИЕ' },
    {
      prop: isBidder ? 'dogovor_customer' : 'name_login',
      label: isBidder ? 'ЗАКАЗЧИК' : 'ФИО',
      subLabel: isBidder ? '' : ['TЕЛЕФОН'],
      minWidth: 210
    }
  ]
}
